import request from '@/utils/request'

/**
 * 客户SOP
 */
export class ContactSopApi {
  // 弹窗h5接口
  static getSopInfoApi (params) {
    return request({
      url: '/contactSop/getSopInfo',
      method: 'get',
      params: params
    })
  }

  // 侧边栏h5接口
  static getSopTipInfoApi (params) {
    return request({
      url: '/contactSop/getSopTipInfo',
      method: 'get',
      params: params
    })
  }

  static getIframeUrl (params) {
    return request({
      url: '/contactSop/iframeUrl',
      method: 'get',
      params: params
    })
  }

  // 客户sop列表
  static getList (params) {
    return request({
      url: '/contactSop/logIndex',
      method: 'get',
      params: params
    })
  }
}
