<template>
  <div class="index">
    <van-nav-bar
      title="客户SOP"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <!-- <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="输入群发账号"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/contactMessageBatchSend/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="search" />
          </div>
        </template>
      </van-search>
    </div> -->
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div style="margin-bottom: 12px; font-size: 12px">管理员提醒你在 [{{ item.sendTime }}] 给客户发送消息</div>
          <div class="list-item-user" v-if="item.contact">
            <van-image
              radius="6"
              width="40"
              height="40"
              :src="item.contact.avatar"
            />
            <div>{{ item.contact.name }}</div>
            <div class="hand">
              <span @click="onChat(item.contact.wxExternalUserid)"><van-icon name="chat-o" /> 打开聊天</span>
            </div>
          </div>
          <div class="list-item-user" v-else>
            <van-image
              radius="6"
              width="40"
              height="40"
            />
            <div>无用户数据</div>
          </div>
          <div style="margin-bottom: -2px; font-size: 12px"><van-icon name="share-o" /> 推荐以下内容</div>
          <div class="task" v-for="(task, taskIndex) in item.task.content" :key="taskIndex">
            <div v-if="task.type == 'text'">{{ task.value }}</div> 
            <div v-if="task.type == 'image'" class="img">
              <img :src="task.value"> 
              <div>长按图片转发</div>
            </div>
            <div class="hand">
              <van-button v-if="task.type == 'text'" size="mini" @click="copyData(task.value)">复制</van-button>
            </div> 
          </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { getCookie } from '@/plugins/utils'
import { ContactSopApi } from '@/api/contactSop'
import { openEnterpriseChat, setClipboardData } from '@/utils/wxCodeAuth'
export default {
  data() {
    return {
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    async copyData (value) {
      Toast('复制成功')
      await setClipboardData(value)
    },
    async onChat (wxId) {
      console.log(wxId)
      await openEnterpriseChat('', wxId)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      ContactSopApi.getList({
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].task = JSON.parse(res.data.data[i].task)
        }
        this.list = this.list.concat(res.data.data || [])
        console.log(this.list)
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {
      // background: #f4f5f7;
      padding: 16px 12px;

      .list-item {
        padding: 12px;
        margin-bottom: 16px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

        &-user {
          border-radius: 12px;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .van-image {
            margin-right: 8px;
          }

          img {
            vertical-align: middle;
          }

          .hand {
            flex: 1;
            text-align: right;
            color: #1989fa;
          }
        }

        .task {
          background: #f2f3f7;
          padding: 10px;
          border-radius: 12px;
          margin-top: 12px;
          display: flex;
          align-items: center;

          .img {
            font-size: 12px;
            color: #999;

            img {
              max-height: 80px;
              margin-bottom: 6px;
            }
          }

          .hand {
            flex: 1;
            text-align: right;
          }
        }

        
      }

      &-item:last-child {
        margin-bottom: 0;
      }
      
  }
}
</style>
